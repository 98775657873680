.self-introduction-container{
    width: auto;
    height: 900px;
    border: dashed 1px black;
    display: flex;
    align-content:center;
    align-items: center;
    // background: url('https://images.unsplash.com/photo-1525088469824-fecdf2298546?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80');
    background: url('../../assets/DALLE_BG.webp');
    // background-size: cover;
    background-position: top left;

    .introduction-container{
        display: flex;
        color: white;
        background-color: rgb(110, 110, 110, 0.55);
        flex-direction: column;
        width: 380px;
        height: 300px;
        text-align: center;
        align-items: center;
        margin-left: 3%;
        margin-top: 12%;
        padding-top: 2.5%;
        border-radius: 10px;


        .button{
            margin-top: 2%;
            width: 250px;
            height: 32px;
            color: white;
            background-color: rgb(14, 14, 14);
        }

        .button:hover{
            background-color: white;
            color: rgb(14, 14, 14);
            border: dashed 1px;
            cursor: pointer;
        }
    }


    
}