.search-selections-containers{
    display: flex;


    .search-selections{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        flex-direction: column;
        padding: 0% 0%;
        font-size: small;

        .search-selections-top{
            display: flex;
            margin-bottom: 0.7%;
            align-items: center;
            justify-content: space-evenly;
            width: 100vw;
            height: 4vh;
            // background-color: rgb(242, 218, 159);



            .material-type-buttons-container{
                padding: 0% 1% 0% 2%;
                font-size: medium
                

            };

        };
        

        .search-selections-middle{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            padding: 0.3% 1% 0% 1%;

            .search-type-container{
                display: flex;
                padding: 0.3% 1.2%;
                margin: 0% 1% 0% 1%;

            
               .search-type-container-left{
                    min-width: 55%;
                    margin-right: 2%;

                
                    
                
               };
    
               .search-type-container-right{
                    max-width: 15%;

                    
               };
    
            }

        };

        .search-selections-bottom{
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            margin-top: 2%;
            justify-content: space-evenly;
            font-size: 12px;


            .search-selections-bottom-lower{
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: space-evenly;
            }
            .material-features-container{
                padding: 0.2% 0.4%;

            }

        }

    

    }
}