.material-detail-section-wrapper{
    display: flex;
    flex-direction: column;
    margin:2% 0% 0% 3%;



    .material-detail-section{
        display: flex;
        flex-direction: row;
        margin-top:5px;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex-shrink: 1;
        min-width: 0;
        


        .material-image{


            img{
                min-width:50vw;
                max-height:90vh;
                max-width: 100vw;


            }

        

        }

        .material-info{
            display: flex;
            flex-direction: column;
            margin: 0% 4% 0% 3%;
            width:35vw;

            .material-info-header{
                font-size: 20px;
            }

            .material-info-body{
                margin-top: 5%;
                height: 50vh;
                display: flex;
                flex-direction: column;
                flex-shrink: 1;


                .material-info-body-button{
                    justify-self: end;

                }

                .attribute{
                    margin:0;
                    
                    
                    .attribute-title{
                        font-size: 15px;
                        color: rgb(114, 114, 114);
                        
                        .attribute-content{
                            font-size: 14px;
                            color: rgb(141, 141, 141);
                        }
                    }
                }
            }

        }
    }
};