.sign-up-container {
    display: flex;
    flex-direction: column;
    width: 380px;

    h1{
      text-decoration: underline;
    }
    h2 {
      margin: 10px 0;
    }
  
    .buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }