body {
  margin: 0;
  padding: 20px 40 px;
  font-family: 'Open Sans', 'sans-serif', 'inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
  color: black;
}

*{
  box-sizing: border-box;
}