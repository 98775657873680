.home-component-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4% 0.5% 4% 0.5%;

}


.cards-title{
  font-size: large;
  margin: 3% 0 3% 0;
  /* text-align: center; */
  max-width: 80vw;
  font-size: medium;
}


.scroll-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  flex-shrink: 2;
}


.left-arrow-container{
  z-index: 1;
  display: flex;
  justify-content: center;
  height:auto;
  width:50px;
  font-size: 35px;
  background-color: rgb(111,111,111);
  color: white;
  border-radius: 50%;
  cursor: pointer;

  margin-left: 1%;


}



.right-arrow-container{
  z-index: 1;
  display: flex;
  justify-content: center;

  height:auto;
  width: 50px;
  font-size: 35px;
  background-color: rgb(111,111,111);
  color: white;
  border-radius: 50%;
  cursor: pointer;

  margin-right: 1%;

}

.left-arrow-container:hover{
  scale: 1.3;
  border: 3.5px solid rgb(247, 206, 56);
  background-color: white;
  color: rgb(111,111,111);
}
.right-arrow-container:hover{
  scale: 1.3;
  border: 3.5px solid rgb(247, 206, 56);
  background-color: white;
  color: rgb(111,111,111);

}


.cards-list {
    z-index: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 3.5%;
    row-gap: 30px;
    justify-content: flex-start;

    align-items: center;
    padding: 4% 6% 8% 6%;
    background-color: rgb(63, 63, 63);
    border-radius: 8%;
    max-width: 72vw;
    flex-shrink: 1;

    overflow: hidden;
    flex-wrap: nowrap;

    background-color: #e5e5f7;
    opacity: 0.85;
    background-image: radial-gradient(circle at center center, rgb(172, 233, 179), #e5e5f7), repeating-radial-gradient(circle at center center, rgb(163, 124, 193), rgb(111,111,111), 10px, transparent 20px, transparent 10px);
    background-blend-mode: multiply;


  }
  
  .card {
    margin: 30px auto;
    width: 225px;
    height: 450px;
    border-radius: 10px;
    box-shadow: 3px 3px 30px 7px rgba(0,0,0,0.25), -3px -3px 30px 7px rgba(0,0,0,0.22);
    cursor: pointer;
    transition: 0.5s;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-shrink: 1;

  }
  
  .card .card-image {
    width: inherit ;
    height: inherit ;
    border-radius: 10px;
  }
  
  .card .card-image img {
    width: inherit ;
    height: inherit;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .card .card-title {
    max-width: 220px;
    color: rgb(255, 255, 255);
    text-align: center;
    border-radius: 20px 20px 20px 20px;
    font-family: sans-serif;
    margin-top: -70px;
    padding: 3%;
    background-color: rgba(195, 195, 195, 0.9);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition:  1.1s
    /* transition:  1.1s cubic-bezier(0.075, 0.52, 0.75, 1.1); */
  }



  .card .text{

    color: white;

    padding: 0% 10% 0% 10%;
    font-size: 12px;
    opacity: 0.9;
    padding: 3%;
    background-color: rgb(0, 0, 0);
    border: dashed 2px;
    box-shadow: 0px 0px 10px 0px;
    transition: 0.9s;
  }

  .text:hover{
    background-color: white;
    color: rgb(111,111,111);
    border: dashed 3px;
  }
  
  .card .card-image:hover {
    
    box-shadow: 4px 4px 20px 10px rgba(0,0,0,0.25), 
      -4px -4px 20px 10px rgba(0,0,0,0.22);

  }

  .card:hover{
    width: 270px;

    scale: 1.03;
    box-shadow: 5px 5px 30px 15px rgba(0,0,0,0.25), 
      -5px -5px 30px 15px rgba(0,0,0,0.22);
    background-color: rgb(255, 255, 255);
    
  }

  .card:hover .card-title{
    transform: translateY(30%);
    background-color: rgb(255, 255, 255);
    padding: 0% 10% 3% 10%;
  }

  .card:hover .card-title .text{

    padding: 0% 40% 0% 40%;
    text-align: center;
    background-color: rgb(230, 230, 230);
    color: rgb(111,111,111);
    border: dashed 3px;
  }
  

  
  @media all and (max-width: 500px) {
    .card-list {
      /* On small screens, we are no longer using row direction but column */
      flex-direction: column;
    }
  }
  
  
  
  /* .card {
    margin: 30px auto;
    width: 300px;
    height: 300px;
    border-radius: 40px;
    background-image: url('https://i.redd.it/b3esnz5ra34y.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-repeat: no-repeat;
  box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
    transition: 0.4s;
  } */
 