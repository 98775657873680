

.header{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0%;
  padding-bottom: 0%;
  position:sticky;
  top:0;
  z-index: 10;
  background-color: rgb(255, 255, 255, 0.16);
  font-weight: 300;





  
  .navigation {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1%;
    font-size: small;
  
    .logo-container {
      height: 100%;
      width: 350px;
      padding: 20px 10px;
      padding-right: 30px;
      text-decoration: underline;
      display: flex;
      flex-direction: row;
      justify-content: space-around;


      .nav-link {
        padding: 10px 15px;
        cursor: pointer;
      }

    }

    
    .nav-links-container {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
  
      .nav-link {
        padding: 10px 15px;
        cursor: pointer;
      }

      .search-elements-container{
        display: flex;
        align-items: center;
        padding-top: 1.5%;
      }
    }


    .bottom-search-type-container{
     max-height: 10vh;
    }
  }

.title{
    display: flex;
    margin-bottom: 0px;
    text-align: center;
    font-weight: 500;
    padding:0.2% 1.5%;
    // border: dashed 1.5px rgb(66, 66, 66, 0.8);
    // background-color: rgba(62, 62, 62, 0.35);
    // color: white;
    // font-weight: bold;
}
}